<template>
  <page v-bind="{ loading }" :icon="'users'" :title="`${Naming.Users} Persona Transactions`">
    <div slot="tools">
      <router-link exact class="button is-rounded is-primary" :to="{ name: 'user-persona-index' }">
        <icon icon="users"/>
        <span>{{ Naming.User }} Personas</span>
      </router-link>
      <router-link exact class="button is-rounded is-primary ml-1" :to="{ name: 'user-persona-create' }">
        <icon icon="plus"/>
        <span>{{ Naming.User }} Persona</span>
      </router-link>

      <router-link exact class="button is-rounded is-primary ml-1" :to="{ name: 'user-persona-transactions' }">
        <icon icon="receipt"/>
        <span>{{ Naming.User }} Persona Transaction</span>
      </router-link>
    </div>

    <div class="box is-paddingless">

      <!-- Filters -->
<!--      <div class="box">-->
<!--        <persona-transaction-filters />-->
<!--      </div>-->

      <table class="table is-fullwidth">
        <thead class="is-sticky">
        <tr>
          <th>Transaction Date</th>
          <th class="has-text-centered">{{ Naming.User }} Persona</th>
          <th class="has-text-centered">{{ Naming.Technicians }} Name</th>
          <th class="has-text-centered">{{ Naming.Technicians }} Email</th>
          <th class="has-text-left">Transaction</th>
          <th class="has-text-centered">Status</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="transaction in transactions?.data" :key="transaction.id">
          <td>{{ transaction.created_at | date('Y-MM-DD')}}</td>
          <td>
            {{ transaction.persona?.name }}
          </td>
          <td class="has-text-centered">{{ transaction.user?.full_name }}</td>
          <td class="has-text-centered">{{ transaction.user?.email }}</td>
          <td class="has-text-left">{{ transaction.transaction }}</td>
          <td class="has-text-centered" v-if="transaction.state === true">
            <icon icon="check-circle" class="has-text-success"></icon>
          </td>
          <td class="is-narrow has-text-centered" v-if="transaction.state === false">
            <action-button
                @click="resubmit(transaction.id)"
                data-tooltip="Retry"
                class="is-borderless"
            >
              <icon icon="retweet" class="has-text-danger"/>
            </action-button>
          </td>
        </tr>
        </tbody>
      </table>
      <p class="has-text-centered" v-if="!transactions?.data?.length">
        No {{ Naming.Users.toLowerCase() }} personas transactions found.
      </p>
      <div class="columns p-1">
        <div class="column">
          <pager class="pl" :pageable="transactions" @nav="goToPage" :context="Naming.User.toLowerCase() + ' persona transactions'"/>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import JobFilters from "@/views/job/partials/Filters.vue";
import TransactionFilters from "@/views/user/personas/partials/Filters.vue"
import PersonaTransactionFilters from "@/views/user/personas/partials/Filters.vue";

export default {
  name: "Index",
  components: {PersonaTransactionFilters, JobFilters},

  data: () => ({
    loading: true,
    filters: {
      role: '',
      search: ''
    }
  }),

  async created() {
    this.filters = {...this.filters, ...this.$route.query}
    await this.loadTransactions()
    this.loading = false
  },

  methods: {
    ...mapActions("userPersonas", []),
    async loadTransactions() {
      this.$scrollToTop()
      return this.$store.dispatch('userPersonas/loadTransactions', {
        path: '/api' + this.$route.fullPath
      }).then(() => {
        this.loading = false
      })
    },
    goToPage(path) {
      this.loading = true
      this.$router.push(path)
    },
    async search() {
      this.$scrollToTop()
      this.loading = true
      this.searchPersonas(this.filters.search).finally(() => {this.loading = false})
    },
    resubmit(transactionId) {
      this.$store.dispatch('userPersonas/resubmit', transactionId)
      this.$toast.success("The Transaction has been resubmitted.")
    },
  },

  computed: {
    ...mapGetters('user', [
      'users'
    ]),
    ...mapGetters('company', [
      'company'
    ]),
    ...mapGetters('userPersonas', [
      'transactions'
    ])
  },

  watch: {
    $route: 'loadTransactions',
  }
}
</script>