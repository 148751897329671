<template>
  <section class="grid gap-1">
    <!-- Header -->
    <header class="is-flex align-end justify-between sticks-to-navbar">
      <div>
        <h3 class="is-size-4 has-text-primary">{{ Naming.Site }} Access</h3>
        <p>Select which {{ Naming.Sites.toLowerCase() }} are available to this {{ Naming.User.toLowerCase() }}:</p>
      </div>
      <form class="flex-grow plr-2">
        <text-input
          class="is-marginless is-rounded"
          classes="is-rounded"
          left-icon="search"
          v-model="filters.search"
          :working="filtering"
          :placeholder="`Filter ${Naming.Sites.toLowerCase()} by name or ${Naming.Client.toLowerCase()} name`"
          :label="false">
          <action-button
            v-if="filters.search"
            slot="right"
            class=""
            @click="clearFilter('search')">
            <icon icon="times"/>
          </action-button>
          <action-button
            slot="right"
            @click="runFilters"
            :disabled="filtering"
            class="is-rounded">
            Search
          </action-button>
        </text-input>
      </form>
      <div class="buttons">
        <action-button
          :disabled="!user.sites.length || isPrimaryUser"
          @click="clearAllSites"
          class="is-rounded is-danger is-inverted">
          Deselect All
        </action-button>
        <action-button
          :disabled="isPrimaryUser"
          @click="selectAllSites"
          class="is-rounded is-success is-inverted">
          Select All
        </action-button>
        <action-button
          :disabled="isPrimaryUser"
          @click="syncSites"
          :working="syncing"
          class="is-rounded is-success has-text-weight-bold"
          left-icon="save">
          Save Changes
        </action-button>
      </div>
    </header>

    <!-- Site Access List -->
    <article class="box p-1">
      <loader v-bind="{ loading }">
        <template v-if="hasSites">
          <pager :pageable="clientGroupedSiteList" @nav="goToPage" :context="Naming.Client.toLowerCase()"/>
          <table class="table is-fullwidth">
            <tbody>
            <tr v-for="client in clientGroupedSiteList.data" :key="client.id">
              <td style="border-bottom: none">
                <div class="flex justify-between align-items-end columns has-bottom-border-cool-blue">
                    <h3 class="column selectable-item-group-title is-inline-block">{{ client.legal_name }}</h3>
                    <div class="pointer column is-narrow buttons has-addons">
                        <action-button @click="selectAllClientSites(client.id)" class="is-success is-inverted">Select all</action-button>
                        <action-button @click="deselectAllClientSites(client.id)" class="is-danger is-inverted">Deselect all</action-button>
                    </div>
                </div>
                <div class="grid has-2-columns column-gap-1">
                  <a v-for="site in client.sites" :key="site.id"
                     :disabled="$root.hasAbility('manage-site-access', 'App\\User') === false"
                     @click="toggleSite(site)"
                     class="is-flex align-center selectable-item"
                     :class="{ 'is-selected': userHasAccessToSite(site) || isPrimaryUser }">
                    <icon
                        class="mr-1"
                        icon="check-circle"
                        :type="userHasAccessToSite(site) ? 'fas' : 'far'"/>
                    <div class="is-flex flex-grow align-center">
                      <span class="has-text-weight-medium"
                            :class="{ 'has-text-grey': !userHasAccessToSite(site) || isPrimaryUser }">{{
                          site.name
                        }}</span>
                      <span class="has-text-grey-light has-text-right flex-shrink-none">&nbsp;— {{
                          site.zones_count || 'No'
                        }} {{ 'zones' | plural(site.zones_count) }}</span>
                    </div>
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <pager :pageable="clientGroupedSiteList" @nav="goToPage" :context="Naming.Client.toLowerCase()"/>

        </template>
        <no-data v-else
          :title="`No ${Naming.Sites}`"
          :description="`There are no ${Naming.Sites.toLowerCase()} available on your account.`"/>
      </loader>
    </article>
  </section>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { filtering } from '@/mixins'
import findIndex from 'lodash/findIndex'
import {common as backend} from "@/api";

export default {

  mixins: [
    filtering
  ],

  watch: {
    $route: 'reloadSites',
  },

  data: () => ({
    loading: true,
    filtering: false,
    syncing: false,
    filters: {
      search: ''
    }
  }),

  computed: {
    ...mapState('site', ['clientGroupedSiteList']),
    ...mapGetters('user', ['user']),
    ...mapGetters('company', ['company']),
    isPrimaryUser() {
      return this.user.id === this.company.primary_user_id
    },
    hasSites() {
      return this.clientGroupedSiteList?.data?.length > 0
    }
  },

  async created() {
    await this.loadSites().then(() => {
      this.loading = false
    })
  },

  methods: {
    ...mapMutations('user', [
      'clearSites'
    ]),
    clearAllSites() {
      this.clearSites()
      this.syncSites()
    },
    toggleSite(site) {
      if(this.isPrimaryUser) return
      this.$store.commit('user/toggleSite', site)
    },
    loadSites() {
      return this.$store.dispatch('site/loadSiteListGroupedByClient', this.$route.query)
    },
    async selectAllSites() {
      this.loading = true
      await this.$store.dispatch('user/selectAllSites', this.user.id)
        .then(() => { this.$toast.success('Sites access updated') })
        .catch((error) => {
            this.$whoops()
        }).finally(() => {
            this.loading = false
        })
    },
    goToPage(path) {
      this.loading = true
      this.$scrollToTop()
      backend.loadPath({
        path: path,
      }, ({ data }) => {
        this.$store.commit('site/setClientGroupedSiteList', data)
        this.loading = false
      }, error => {
        this.$whoops()
        this.loading = false
      })
    },
    async reloadSites() {
      this.filtering = true
      await this.loadSites()
      this.filtering = false
    },

    userHasAccessToSite(site) {
      return findIndex(this.user.sites, { id: site.id }) >= 0
    },
    async syncSites() {
      this.syncing = true
      this.$store
        .dispatch('user/syncSites')
        .then(() => {
          this.$toast.success(this.Convert('Sites access updated'))
        })
        .catch((error) => {
          if (error.response.status === 406) {
            this.$alert('Notice', error.response.data)
            return
          }
          this.$whoops()
        })
        .finally(async () => {
          await this.$store.dispatch('user/loadUser', this.$route.params.user)
          this.syncing = false
        })
    },
    selectAllClientSites(clientId){
        const sites = this.getClientSites(clientId)
        this.$store.commit('user/addClientSitesToUserSites', sites)
    },
    deselectAllClientSites(clientId){
        const sites = this.getClientSites(clientId)
        this.$store.commit('user/removeClientSitesFromUserSites', sites)
    },
    getClientSites(clientId){
        return this.clientGroupedSiteList.data.filter(client => +client.id === clientId)[0].sites
    }
  },
}
</script>
