<template>
<loader v-bind="{ loading }">
  <div class="columns is-multiline">
    <div class="column is-narrow">
      <summary-item
        class="box"
        icon="hashtag"
        :title="Naming.Jobs"
        :value="stats.job_total"/>
    </div>
    <div class="column is-narrow">
      <summary-item
        class="box"
        icon="clock"
        :title="`Open ${Naming.Jobs}`"
        :value="stats.job_total - stats.completed_jobs"/>
    </div>
    <div class="column is-narrow">
      <summary-item
        class="box"
        icon="clock"
        :title="Naming.Inspections"
        :value="stats.inspections"/>
    </div>
    <div class="column is-narrow">
      <summary-item
        class="box"
        icon="exclamation-triangle"
        icon-class="has-text-danger"
        :title="`Failed ${Naming.Inspections}`"
        :value="stats.failed_inspections"/>
    </div>
    <div class="column is-narrow">
      <summary-item
        class="box"
        icon="check-circle"
        icon-class="has-text-success"
        :title="`Passed ${Naming.Inspections}`"
        :value="stats.passed_inspections"/>
    </div>
    <div class="column is-narrow">
      <summary-item
        class="box"
        icon="clock"
        :title="`Last ${Naming.Inspection}`"
        :value="latestInspectionDate"/>
    </div>
  </div>

  <div class="columns" v-if="markers.length">
    <div class="column">
      <h4 class="is-size-4">{{ Naming.Inspections }} This Month</h4>
      <div class="box is-paddingless is-clipped">
        <GmapMap
          :center="centerPoint"
          :zoom="12"
          :options="googleMapOptions"
          map-type-id="terrain"
          style="width: 100%; height: 600px">
          <GmapMarker
            v-for="marker in markers"
            :key="marker.id"
            :position="{ lat: marker.latitude, lng: marker.longitude }"/>
        </GmapMap>
      </div>
    </div>
  </div>
</loader>
</template>

<script>
import { mapGetters } from 'vuex'
import defaultMapStyles from '@/theme/gmaps/default'

export default {

  data: () => ({
    loading: true,
    googleMapOptions: {
      styles: defaultMapStyles
    }
  }),

  created() {
    Promise.all([
      this.$store.dispatch('user/loadStats', this.$route.params.user),
      this.$store.dispatch('user/loadLatestLocations', this.$route.params.user)
    ]).then(() => {
      this.loading = false
    })
  },

  computed: {
    ...mapGetters('user', [
      'stats',
      'latestInspections'
    ]),
    markers() {
      return this.latestInspections
    },
    centerPoint() {
      if(this.markers.length) return {
        lat: this.markers.reduce((carry, marker) => carry + marker.latitude, 0) / this.markers.length,
        lng: this.markers.reduce((carry, marker) => carry + marker.longitude, 0) / this.markers.length,
      }
      return { lat: -29.756377840872975, lng: 31.026159912609653 }
    },
    latestInspectionDate() {
      return this.stats.latest_inspection ? this.$options.filters.date(this.stats.latest_inspection, 'L LT') : '--'
    }
  }

}
</script>
