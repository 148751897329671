<template>
  <div class="sub-asset-item is-flex align-center justify-between" :class="{'is-staged' : isStaged}">
    <div class="is-flex align-center justify-between">
      <icon icon="user" class="is-size-6 grab-cursor"/>
      <div class="is-flex align-center">
        <p class="has-tooltip sub-asset-item-text"
           :class="{'assigned' : !isAvailableItems}"
           :data-tooltip="`${Naming.Technician}: ${technician.full_name}`">
          {{ technician.full_name }}
        </p>
        <p v-if="isAvailableItems"
           class="ml-1 has-tooltip sub-asset-item-text is-capitalized"
           :data-tooltip="`${Naming.Technician}`">
          <icon class=" is-inline is-size-7" icon="address-book"/>
          {{ technician.email }}
        </p>
        <p v-if="isAvailableItems"
           class="ml-1 has-tooltip sub-asset-item-text"
           :data-tooltip="`${Naming.Technician} Creation Date`">
          <icon class=" is-inline is-size-7" icon="calendar"/>
          {{ technician.created_at | date('Y-MM-DD') }}
        </p>
        <p v-if="!isAvailableItems"
           class="ml-1 has-tooltip sub-asset-item-text is-capitalized"
           :data-tooltip="`${Naming.Technician}`">
          <icon class=" is-inline is-size-7" icon="address-book"/>
          {{ technician.technical_jobs_open_count }}
        </p>
      </div>
    </div>
    <icon @click="pushToSelected" v-if="isAvailableItems === true"
          class="is-size-5 pl-1 pr-1 hover-highlight-success" icon="plus-circle"/>
    <icon @click="removeFromSelected" v-if="isAvailableItems === false" class="is-size-5 pl-1 pr-1 hover-highlight-danger"
          icon="times-circle"/>
  </div>
</template>

<script>

export default {
  name: 'technician-item',

  props: {
    technician: {
      type: Object,
    },
    isAvailableItems: {
      type: Boolean,
      default: false
    },
    staged: {
      type: Array
    }
  },

  methods: {
    pushToSelected() {
      this.$emit('pushToSelected', this.technician)
    },
    removeFromSelected() {
      this.$emit('removeFromSelected', this.technician)
    },
  },

  computed: {
    isStaged(){
      return this.staged?.includes(this.technician)
    }
  }
}
</script>