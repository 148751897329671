<template>
<page :title="`Technical ${Naming.Users}`" icon="user" v-bind="{ loading }" :loading-text="`Loading ${Naming.Users}…`">
  <div slot="tools">
    <action-button class="is-warning" @click="addTech" left-icon="plus">
      {{ Naming.Technician }}
    </action-button>
  </div>

  <div class="box is-paddingless">
    <div class="columns">
      <div class="column">
        <table class="table is-fullwidth is-vcentered is-hoverable">
          <thead class="is-sticky">
            <tr>
              <th>Full Name</th>
              <th>Mobile</th>
              <th>Email</th>
              <th>{{ Naming.Jobs }}</th>
              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users.data" :key="user.id">
              <td>
                <link-user-overview avatar :user="user"/>
              </td>
              <td>
                <span v-if="user.profile">{{ user.profile.formatted_mobile }}</span>
              </td>
              <td>{{ user.email }}</td>
              <td>{{ user.technical_jobs_count }}</td>
              <td>
                <yes-no :value="user.active"/>
              </td>
            </tr>
          </tbody>
        </table>
        <p class="has-text-centered" v-if="!users.data.length">
          No {{ Naming.Users }} found
        </p>
        <pager class="pl" :pageable="users" @nav="goToPage" :context="Naming.User.toLowerCase()"/>
      </div>
    </div>
  </div>
</page>
</template>

<script>
import { mapGetters } from 'vuex'
import { createTechnician } from '@/modals'

export default {

  data: () => ({
    loading: true,
  }),

  async created() {
    await this.loadUsers()
    this.loading = false
  },

  methods: {
    loadUsers() {
      this.$scrollToTop()
      return this.$store.dispatch('user/loadUsers', {
        path: '/api' + this.$route.fullPath,
        params: this.$route.query
      })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    addTech() {
      createTechnician().then(reload => {
        if(reload) this.loadUsers()
      })
    }
  },

  computed: {
    ...mapGetters('user', [
      'users'
    ])
  },

  watch: {
    '$route': 'loadUsers'
  }
}
</script>
