<template>
<base-modal wide :title="`New ${Naming.Technician}`">
  <loader v-bind="{ loading }">
    <form>
      <div class="columns">
        <div class="column">
          <text-input 
            required
            classes="is-medium"
            left-icon="user" 
            :error="$root.errors.full_name || $root.errors.last_name"
            :value="user.full_name"
            @input="fullName">
            Full Name
          </text-input>
        </div>
        <div class="column">
          <text-input 
            required
            classes="is-medium"
            left-icon="envelope" 
            :error="$root.errors.email"
            :value="user.email"
            @input="email">
            Email Address
          </text-input>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <intl-phone 
            size="is-medium"
            :number="user.profile.mobile" 
            :code="user.profile.dialing_code" 
            @code="dialingCode"
            @number="mobile"/>
        </div>
        <div class="column">
          <data-selector  
            required
            :error="$root.errors.timezone"
            :value="user.timezone"
            :items="sortedTimezones"
            @input="updateTimezone"
            classes="is-medium"
            searchable>
            Timezone
          </data-selector>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <h2 class="is-size-5">{{ Naming.Sites }}</h2>
          <p class="mb">Select which {{ Naming.Sites.toLowerCase() }} are available to this {{ Naming.User.toLowerCase() }}.</p>
          <data-selector
            :prompt-label="`Select one or more ${Naming.Sites}…`"
            :error="$root.errors.sites"
            multiple
            left-icon="building"
            :items="siteList"
            label-key="name"
            value-key="id"
            :value="user.sites"
            @input="sites">
            <data-selector-site-slot
              slot="item"
              slot-scope="{ item }"
              :site="item"
            />
          </data-selector>
        </div>
      </div>
    </form>

  </loader>

  <template #footer v-if="!loading">
    <div class="columns">
      <div class="column has-text-right">
        <submit-button :working="working" @submit="store" class="is-success" left-icon="check-circle">Create {{ Naming.User }}</submit-button>
      </div>
    </div>
  </template>
</base-modal>  
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {

  data: () => ({
    working: false,
    loading: true
  }),

  beforeCreate() {
    this.$store.commit('user/clear')
    Promise.all([
      this.$store.dispatch('site/loadSiteList'),
      this.$store.dispatch('list/loadTimezones')
    ]).then(() => {
      this.loading = false
    })
  },

  methods: {
    ...mapMutations('user', [
      'name',
      'lastName',
      'fullName',
      'email',
      'password',
      'passwordConfirmation',
      'roles',
      'mobile',
      'dialingCode',
      'sites',
      'updateTimezone'
    ]),
    store() {
      
      this.working = true
      let full_name = this.user.full_name.split(" ")
      this.name(full_name[0])
      this.lastName(full_name[1])

      this.$store.dispatch('user/storeTechnician').then(user => {
        this.working = false
        this.$toast.success(this.Convert('User Successfully Created'))
        this.$close(user)
      }).catch(error => {
        if(error.response && error.response.status === 402) {
          this.$alert('Error Processing Payment', error.response.data)
        }
        this.working = false
      })
    }
  },

  computed: {
    ...mapGetters('user', [
      'user'
    ]),
    ...mapGetters('list', [
      'timezones'
    ]),
    ...mapGetters('company', [
      'subscription'
    ]),
    ...mapGetters('site', [
      'siteList'
    ]),
    sortedTimezones() {
      return this.timezones.map(timezone => {
        return { 
          value: timezone,
          label: timezone 
        }
      })
    }
  }

}
</script>
