<template>
  <page icon="users" :title="`Edit ${Naming.User} Persona`" v-bind="{ loading }" :loadingText="loadingText">
    <template #tools>
      <router-link exact class="button is-rounded is-primary" :to="{ name: 'user-persona-index' }">
        <icon icon="users"/>
        <span>{{ Naming.User }} Personas</span>
      </router-link>
      <router-link exact class="button is-rounded is-primary ml-1" :to="{ name: 'user-persona-transactions' }">
        <icon icon="receipt"/>
        <span>{{ Naming.User }} Persona Transaction</span>
      </router-link>
    </template>

    <form class="box">
      <div class="columns is-multiline">
        <div class="column mr-4">
          <text-input
              placeholder="ex: Region Group, Collective Area, etc…"
              required
              :value="persona.name"
              @input="name">
            Name
          </text-input>
        </div>
        <div class="columns mr-4 mt-2">
          <submit-button :working="loading" @submit="store" class="is-success is-medium" :disabled="!canSave"
                         left-icon="check">Update
            {{ Naming.User }} Persona
          </submit-button>
        </div>
      </div>
      <br/>
      <div v-if="showPage==='sites'">
        <div class="level mb-1">
          <div class="level-left justify-between level-item">
            <h3 class="is-size-5 level-item">Assign {{ Naming.Sites }} to {{ Naming.User }} Persona</h3>
            <action-button
                @click="editTechnicianList"
                left-icon="user"
                class="button is-primary is-rounded mr-1">
              Edit {{ Naming.Users }}
            </action-button>
          </div>
        </div>
        <div class="box">
          <div class="grid has-5-columns gap-1">
            <!--ASSIGNED SITES-->
            <div class="is-2-columns">
              <div class="tab">Assigned To {{ Naming.User }} Persona</div>
              <draggable :list="selectedSites" group="subAssetSelector"
                         class="sub-asset-draggable has-background-white-bis is-fullheight"
                         v-if="selectedSites.length < maximumSitesToDisplay"
              >
                <div v-if="hasSelectedSites" v-for="(site, index) in selectedSites" :key="site.id">
                  <assigned-site-item :available-items="false" :index="index"
                                      :site="site" :staged="stagedSites"
                                      @removeFromSelected="removeSiteFromSelectedSites"/>
                </div>
                <div v-if="!hasSelectedSites" class="has-text-grey p-1">No {{ Naming.Sites }} yet. Select or drag from
                  available {{ Naming.Sites }}.
                </div>
              </draggable>
              <draggable :list="selectedSites" group="subAssetSelector"
                         class="sub-asset-draggable has-background-white-bis is-fullheight"
                         v-else
              >
                <div class="grid has-1-columns gap-1 p-1 has-text-centered">
                  Showing first {{ this.maximumSitesToDisplay }} of {{ this.selectedSites.length}} selected {{ Naming.Sites}}
                </div>
                <div v-if="hasSelectedSites" v-for="(site, index) in abbreviatedSelectedSites" :key="site.id">
                  <site-item :available-items="false" :index="index"
                             :site="site" :staged="stagedSites"
                             @removeFromSelected="removeSiteFromSelectedSites"/>
                </div>
                <div v-if="!hasSelectedSites" class="has-text-grey p-1">No {{ Naming.Sites }} yet. Select or drag from
                  available {{ Naming.Sites }}.
                </div>
              </draggable>
            </div>

            <!--AVAILABLE SITES-->
            <div class="is-3-columns">
              <div class="tab available is-flex justify-between">
                <div class="">Available {{ Naming.Sites }}
                </div>
                <div>
                  Showing {{ this.availableSites?.data?.length }} of {{ calculatedTotalSites }}
                </div>
              </div>

              <div class="search-box grid has-4-columns gap-1">
                <div class="is-flex is-3-columns">
                  <input
                      type="text"
                      v-model="search"
                      class="input"
                      placeholder="Search by name"
                  />
                  <div class="is-flex">
                    <action-button
                        v-if="search"
                        @click="clearSearch()"
                        class="is-info-lighter">
                      <span>Clear</span>
                      <icon icon="times"/>
                    </action-button>
                  </div>
                  <div class="is-flex">
                    <action-button @click="searchAvailableSites()" class="ml-1 is-info-lighter">Search</action-button>
                  </div>
                </div>
                <action-button left-icon="plus-circle" class="is-pulled-right" @click="addAllSites()" :working="addingAllSites">Add All {{ Naming.Sites }} to this Persona</action-button>
              </div>

              <div class="search-box grid has-4-columns gap-1">
                <div class="is-flex is-3-columns">
                  <checkbox-input :value="searchClientsOnly" inner-label="Search Clients Only" @input="toggle('searchClientsOnly')"/>
                  <checkbox-input v-if="searchClientsOnly" :value="returnAllSitesForClient" inner-label="Return All Sites for Client" @input="toggle('returnAllSitesForClient')"/>
                </div>
                <div>&nbsp;</div>
              </div>
              <div class="search-box grid has-3-columns gap-1">
                <div class="is-flex">
                  <action-button @click="selectAll()" class="is-info-lighter">Select All</action-button>
                  <action-button left-icon="sort" @click="sortSites('name')">Site Name</action-button>
                  <action-button left-icon="sort" @click="sortSites('client')">Client Name</action-button>
                </div>
              </div>

                <draggable :list="this.availableSites?.data" group="subAssetSelector" class="sub-asset-draggable">
                  <div v-for="(availableSite, index) in this.availableSites.data" :key="availableSite.id">
                    <site-item v-if="!loadingSites" :is-available-items="true"
                               :index="index" class="available-item"
                               :site="availableSite" @pushToSelected="pushSiteToSelected"/>
                  </div>
                  <loader v-if="loadingMoreSites" v-bind="{ loadingMoreSites }"
                          :text="`Loading More ${Naming.Sites}`"></loader>
                  <div v-if="moreSitesAvailable">
                    <div
                        class="persona-load-more-box has-text-grey is-flex-column align-items-center justify-between"
                        @click="loadMoreSites()"
                    >
                      <div class="has-text-grey has-text-centered p-05">
                        Showing {{ this.availableSites?.data?.length }} of {{ calculatedTotalSites }}
                      </div>
                      <icon class="is-size-5" icon="plus-circle"></icon>
                    </div>
                  </div>
                  <!--placeholder to give height when no subasset items-->
                  <div class="p-2">&nbsp</div>
                </draggable>
                <div v-if="!this.availableSites?.data">
                  <div class="has-text-grey p-05">No additional available {{ Naming.Sites.toLowerCase() }}.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div v-if="showPage==='technicians'">
        <div class="level mb-1">
          <div class="level-left justify-between level-item">
            <h3 class="is-size-5 level-item">Assign {{ Naming.Technicians }} to {{ Naming.User }} Persona</h3>
            <action-button
                @click="editSiteList"
                left-icon="building"
                class="button is-primary is-rounded mr-1">
              Edit {{ Naming.Sites }}
            </action-button>
          </div>
        </div>
        <div class="box">
          <div class="grid has-5-columns gap-1">
            <!--ASSIGNED TECHNICIANS-->
            <div class="is-2-columns">
              <div class="tab">Assigned To {{ Naming.User }} Persona</div>
              <draggable :list="selectedTechnicians" group="subAssetSelector"
                         class="sub-asset-draggable has-background-white-bis is-fullheight">
                <div v-if="hasSelectedTechnicians" v-for="(technician, index) in selectedTechnicians"
                     :key="technician.id">
                  <assigned-technician-item :available-items="false" :index="index"
                                            :technician="technician" :staged="stagedTechnicians"
                                            @removeFromSelected="removeTechnicianFromSelectedTechnicians"/>
                </div>
                <div v-if="!hasSelectedTechnicians" class="has-text-grey p-1">No {{ Naming.Technicians }} yet. Select or
                  drag from
                  available {{ Naming.Technicians }}.
                </div>
              </draggable>
            </div>

            <!--AVAILABLE TECHNICIANS-->
            <div class="is-3-columns">
              <div class="tab available is-flex justify-between">
                <div class="">Available {{ Naming.Technicians }}
                </div>
                <div>
                  Showing {{ this.availableTechnicians?.data?.length }} of {{ calculatedTotalTechnicians }}
                </div>
              </div>

              <div>
                <div class="search-box grid has-4-columns gap-1">
                  <div class="is-flex is-3-columns">
                    <input
                        type="text"
                        v-model="search"
                        class="input"
                        placeholder="Search by name"
                        @input="debouncedSearchTechnicians"
                    />
                    <div class="is-flex">
                      <action-button
                          v-if="search"
                          @click="clearTechnicianSearch()"
                          class="is-info-lighter">
                        <span>Clear</span>
                        <icon icon="times"/>
                      </action-button>
                    </div>
                  </div>
                  <action-button @click="selectAllTechnicians()" class="is-info-lighter">Select All</action-button>
                  <action-button left-icon="sort" @click="sortTechnicians('full_name')">Technician Name</action-button>
                </div>

                <!-- draggable technician list -->
                <div v-if="!technicianListLoading">
                  <draggable :list="this.availableTechnicians?.data" group="subAssetSelector"
                             class="sub-asset-draggable">
                    <div v-for="(availableTechnician, index) in this.availableTechnicians?.data"
                         :key="availableTechnician.id">
                      <technician-item v-if="!loadingTechnicians" :is-available-items="true"
                                       :index="index" class="available-item"
                                       :technician="availableTechnician" @pushToSelected="pushTechnicianToSelected"/>
                    </div>
                    <loader v-if="loadingMoreTechnicians" v-bind="{ loadingMoreTechnicians }"
                            :text="`Loading More ${Naming.Technicians}`"></loader>
                    <div v-if="moreTechniciansAvailable">
                      <div
                          class="persona-load-more-box has-text-grey is-flex-column align-items-center justify-between"
                          @click="loadMoreTechnicians()"
                      >
                        <div class="has-text-grey has-text-centered p-05">
                          Showing {{ this.availableTechnicians?.data?.length }} of {{ calculatedTotalTechnicians }}
                        </div>
                        <icon class="is-size-5" icon="plus-circle "></icon>
                      </div>
                    </div>
                    <!--placeholder to give height when no technician items-->
                    <div class="p-2">&nbsp</div>
                  </draggable>
                  <div v-if="!this.availableTechnicians?.data">
                    <div class="has-text-grey p-05">No additional available {{
                        Naming.Technicians.toLowerCase()
                      }}.
                    </div>
                  </div>
                </div>
                <loader v-if="technicianListLoading" v-bind="{ technicianListLoading }"
                        :text="`Loading ${Naming.Technicians}`"></loader>
                <!-- end draggable technician list -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <br/>
    </form>

  </page>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import ChangeZone from "@/views/asset/manager/partials/ChangeZone.vue";
import SiteItem from "@/views/user/personas/partials/SiteItem.vue";
import {common as backend} from "@/api";
import AssignedSiteItem from "@/views/user/personas/partials/AssignedSiteItem.vue";
import AssignedTechnicianItem from "@/views/user/personas/partials/AssignedTechnicianItem.vue";
import TechnicianItem from "@/views/user/personas/partials/TechnicianItem.vue";
import {debounce} from "lodash";

export default {
  components: {TechnicianItem, AssignedTechnicianItem, AssignedSiteItem, SiteItem, ChangeZone},

  data: () => ({
    maximumSitesToDisplay: 100,
    loading: false,
    search: '',
    loadingSites: false,
    loadingMoreSites: false,
    loadingTechnicians: false,
    loadingMoreTechnicians: false,
    technicianListLoading: false,
    sortDescending: {
      name: false,
      created_at: false,
    },
    showPage: 'sites',
    searchClientsOnly: false,
    returnAllSitesForClient: false,
    addingAllSites: false,
    processingTextSiteCountLimit: 1000,
  }),

  async created() {
    await this.loadAvailableTechnicians()
  },

  async mounted() {
    await this.loadUserPersona()
    await this.loadAvailableSites()
  },

  beforeDestroy() {
    this.clearUserPersona()
  },

  methods: {
    ...mapMutations('userPersonas', [
      'clearUserPersona',
      'name',
      'sites',
    ]),
    toggle(item) {
      if(item) {
        this[item] = !this[item]
      }
    },
    debouncedSearchTechnicians: debounce(function () {
      this.loadingMoreSites = true
      this.searchAvailableTechnicians()
    }, 1000),
    async store() {
      this.loading = true
      let payload = {
        persona_id: this.persona.id,
        name: this.persona.name,
        sites: this.selectedSites.map((site) => {
          return {site_id: site.id}
        }),
        technicians: this.selectedTechnicians.map((technician) => {
          return {id: technician.id}
        })
      }
      this.$store.dispatch('userPersonas/update', payload).then(async userPersona => {
        this.$toast.success(this.Convert(this.$lang.userPersona.updated))
        this.loading = false

        if (await this.$alert(
          "Persona",
          "The Persona has been submitted for updating. It may take some time to process. Please review the Transactions to see the state."
        )) {
         this.$router.back()
        }
      }).catch(error => {
        this.loading = false
        if(error.response?.status === 422) {
          this.$toast.warning("The Name already exists for this Persona.")
        }else {
          this.$toast.error("An error occurred, please try again or contact support.")
        }
      })
    },
    async addAllSites() {
      //  we need to mark the action as busy
      this.addingAllSites = true
      this.loadingSites = true
      this.loadingMoreSites = true

      await this.$store.dispatch('userPersonas/addAllSites').then(() => this.loadAvailableSites()).catch((error) => {
        console.log(error)
        this.$whoops()
      }).finally(() => {
        this.addingAllSites = false
        this.loadingSites = false
        this.loadingMoreSites = false
      })
    },
    async searchAvailableSites() {
      this.loadingMoreSites = true
      this.availableSites.data = []
      backend.loadPathPost(
          this.availableSites.links.first,
          this.getPayload(),
          ({data}) => {
            this.availableSites.links.next = data.links.next
            this.availableSites.meta = data.meta
            data.data.forEach(site => this.availableSites.data.push(site))
          },
          error => {
          })
          .finally(() => {
            this.loadingMoreSites = false
          })
    },
    async searchAvailableTechnicians() {
      this.technicianListLoading = true
      this.availableTechnicians.data = []
      backend.loadPathPost(
          this.availableTechnicians.links.first,
          this.getPayload(),
          ({data}) => {
            this.availableTechnicians.links.next = data.links.next
            this.availableTechnicians.meta = data.meta
            data.data.forEach(technician => this.availableTechnicians.data.push(technician))
          },
          error => {
          })
          .finally(() => {
            this.technicianListLoading = false
          })
    },
    async loadAvailableSites() {
      this.loadingSites = true
      const payload = this.getPayload()
      await this.callStoreAction('userPersonas/availableSites', payload).finally(() => this.loadingSites = false)
    },
    async loadAvailableTechnicians() {
      this.technicianListLoading = true
      const payload = this.getPayload()
      await this.callStoreAction('userPersonas/availableTechnicians', payload).finally(() => this.technicianListLoading = false)
    },
    async callStoreAction(action, payload) {
      await this.$store.dispatch(
          action,
          payload
      ).catch((error) => {
        this.$whoops()
      })
    },
    getPayload() {
      return {
        search: this.search,
        selected_site_ids: this.selectedSiteIds,
        selected_technician_ids: this.selectedTechnicianIds,
        search_clients_only: this.searchClientsOnly,
        return_all_sites_for_client: this.returnAllSitesForClient
      }
    },
    removeSiteFromSelectedSites(site) {
      this.$store.commit('userPersonas/removeSite', site)
      this.availableSites.data.push(site)
    },
    removeTechnicianFromSelectedTechnicians(technician) {
      this.$store.commit('userPersonas/removeTechnician', technician)
      this.availableTechnicians.data.push(technician)
    },
    clearSearch() {
      this.search = ''
      this.searchClientsOnly = false
      this.returnAllSitesForClient = false
      this.loadAvailableSites()
    },
    clearTechnicianSearch() {
      this.search = ''
      this.loadAvailableTechnicians()
    },
    selectAll() {
      this.availableSites.data.forEach(site => this.selectedSites.push(site))
      this.loadAvailableSites()
    },
    selectAllTechnicians() {
      this.availableTechnicians.data.forEach(technician => this.selectedTechnicians.push(technician))
      this.loadAvailableTechnicians()
    },
    sortSites(key) {
      let isDescending = this.sortDescending[key]
      this.availableSites.data = this.sortArray(this.availableSites?.data, key, isDescending)
      this.sortDescending[key] = !this.sortDescending[key]
    },
    sortArray(array, key, descending = false) {
      return array.sort((a,b) => {
        let valueA = a[key]
        let valueB = b[key]

        if(typeof valueA === 'string') {
          valueA = valueA?.toLowerCase()
          valueB = valueB?.toLowerCase()
        }

        return descending ? (valueB?.localeCompare(valueA)) : (valueA?.localeCompare(valueB))
      })
    },
    sortTechnicians(key) {
      let isDescending = this.sortDescending[key]
      this.availableTechnicians.data = this.sortArray(this.availableTechnicians?.data, key, isDescending)
      this.sortDescending[key] = !this.sortDescending[key]
    },
    pushSiteToSelected(site) {
      let index = this.availableSites.data.indexOf(site)
      this.availableSites.data.splice(index, 1)
      this.selectedSites.push(site)
    },
    pushTechnicianToSelected(technician) {
      let index = this.availableTechnicians?.data?.indexOf(technician)
      this.availableTechnicians?.data?.splice(index, 1)
      this.selectedTechnicians?.push(technician)
    },
    loadMoreSites() {
      this.loadingMoreSites = true
      let payload = this.getPayload()
      this.apiRequest('availableSites', payload)
    },
    loadMoreTechnicians() {
      this.loadingMoreTechnicians = true
      let payload = this.getPayload()
      this.apiRequest('availableTechnicians', payload)
    },
    apiRequest(type, payload) {
      backend.loadPathPost(
          this[type].links.next,
          payload,
          ({data}) => {
            this[type].links.next = data.links.next
            this[type].meta = data.meta
            data.data.forEach(item => this[type].data.push(item))
          },
          error => {
          })
          .finally(() => {
            this.loadingMoreTechnicians = false
            this.loadingMoreSites = false
          })
    },
    debounce(callback, delay = 300) {
      let timer
      return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          callback.apply(this, args)
        }, delay)
      }
    },
    async loadUserPersona() {
      this.loading = true
      await this.$store.dispatch('userPersonas/loadPersona', this.$route.params.persona)
      this.loading = false
    },
    editTechnicianList() {
      this.showPage = 'technicians'
      this.clearSearch()
    },
    editSiteList() {
      this.showPage = 'sites'
      this.clearSearch()
    },

  },

  computed: {
    ...mapGetters('userPersonas', [
      'persona',
      'selectedSites',
      'availableSites',
      'selectedTechnicians',
      'availableTechnicians',
    ]),
    abbreviatedSelectedSites() {
      return this.selectedSites.slice(0, this.maximumSitesToDisplay)
    },
    hasSelectedSites() {
      return this.selectedSites?.length > 0
    },
    hasSelectedTechnicians() {
      return this.selectedTechnicians?.length > 0
    },
    stagedSites() {
      if (this.selectedSites && this.persona.sites) {
        const transformedSelectedSites = this.selectedSites.map((site) => {
          return {
            site_id: site.id,
            name: site.name
          }
        })
        return transformedSelectedSites.filter(site => !this.persona.sites.includes(site))
      }

      return []
    },
    stagedTechnicians() {
      if (this.selectedTechnicians && this.persona.technicians) {

        const transformedSelectedTechnicians = this.selectedTechnicians.map((technician) => {
          return {
            technician_id: technician.id,
            name: technician.full_name
          }
        })

        return transformedSelectedTechnicians.filter(technician => !this.persona.technicians.includes(technician))
      }
      return []
    },
    selectedSiteIds() {
      return this.selectedSites.map(site => site.id)
    },
    selectedTechnicianIds() {
      return this.selectedTechnicians.map(technician => technician.id)
    },
    calculatedTotalSites() {
      if (this.moreSitesAvailable) {
        const toBeLoaded = this.availableSites?.meta?.total - this.availableSites?.meta?.to
        return this.availableSites?.data?.length + toBeLoaded
      }
      return this.availableSites?.data?.length
    },
    calculatedTotalTechnicians() {
      if (this.moreTechniciansAvailable) {
        const toBeLoaded = this.availableTechnicians?.meta?.total - this.availableTechnicians?.meta?.to
        return this.availableTechnicians?.data?.length + toBeLoaded
      }
      return this.availableTechnicians?.data?.length
    },
    moreSitesAvailable() {
      return this.availableSites?.links?.next?.length > 0
    },
    moreTechniciansAvailable() {
      return this.availableTechnicians?.links?.next?.length > 0
    },
    canSave() {
      return this.persona.name.length > 2 && this.selectedSites.length > 0
    },
    loadingText() {
      if(this.selectedSites.length > this.processingTextSiteCountLimit) {
        return `Processing ${this.selectedSites.length} ${ this.Naming.Sites }, this may take a while.`
      }

      return "Loading..."
    },
  },
}
</script>
