<template>
  <div>
    <template v-if="loaded">
      <div class="columns is-multiline">
        <div class="column is-3">
          <server-data-selector
              searchable
              class="is-marginless"
              :value="filters.personas"
              :items="personaList"
              v-model="filters.personas"
              left-icon="users"
              value-key="id"
              label-key="name"
              :prompt-label="`Filter by ${Naming.User} Persona`"
              @input="filterBy('personas', $event)"
              :on-type="searchPersona"
              :on-lose-focus="clearPersonaFilter">
            <data-selector-user-slot
                slot="item"
                slot-scope="{ item }"
                :user="item"/>
            <action-button
                v-if="filters.personas"
                slot="right"
                @click="clearPersonaFilter()">
              <icon icon="times"/>
            </action-button>
          </server-data-selector>
        </div>

        <div class="column is-3">
          <server-data-selector
              searchable
              class="is-marginless"
              :value="filters.technician"
              :items="techniciansList"
              v-model="filters.technician"
              left-icon="user"
              value-key="id"
              label-key="full_name"
              :prompt-label="`Filter by ${Naming.Technician}`"
              @input="filterBy('technician', $event)"
              :on-type="searchTechnician"
              :on-lose-focus="clearTechnicianFilter">
            <data-selector-user-slot
                slot="item"
                slot-scope="{ item }"
                :user="item"/>
            <action-button
                v-if="filters.technician"
                slot="right"
                @click="clearTechnicianFilter()">
              <icon icon="times"/>
            </action-button>
          </server-data-selector>
        </div>

        <div class="column is-3">
          <date-picker
              class="is-marginless"
              placeholder="Filter by start date (range)"
              mode="range"
              left-icon="calendar"
              :label="false"
              ref="startDate"
              :value="filters.start_date"
              @input="filterStartDate">
            <action-button
                v-if="filters.start_date"
                slot="right"
                @click="clearFilter('start_date')">
              <icon icon="times"/>
            </action-button>
          </date-picker>
        </div>
        <div class="column is-3">
          <checkbox-input :value="filters.status" @input="filterBy('status', $event)" inner-label="Show Failed Only"/>
        </div>
      </div>
    </template>
    <p v-else class="has-text-cool-blue-text">Loading Filters…</p>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {filtering} from '@/mixins'
import Search from '@/utils/search'

export default {

  name: 'persona-transaction-filters',

  mixins: [
    filtering
  ],

  data: () => ({
    loaded: false,
    techniciansList: [],
    personaList: [],
    filters: {
      search: '',
      technician: '',
      start_date: '',
      status: false,
      personas: '',
    },
  }),

  computed: {
    ...mapGetters('user', [
      'userList'
    ]),
    ...mapGetters('userPersonas', [
        'personas'
    ]),
  },

  async created() {
    let {status, start_date} = this.$route.query
    Search.searchByUri('', 'user/searchTechnician', this.$store, 0)
    await this.searchPersonas()
    this.loaded = true
  },

  methods: {
    ...mapMutations('user', [
      'setUserList',
    ]),
    ...mapMutations('userPersonas', [
        'setPersonaList'
    ]),
    ...mapActions('userPersonas', [
        'searchPersonas'
    ]),

    filterStartDate(range) {
      if (!range) {
        this.clearFilter('start_date')
        return
      }
      this.filterBy('start_date', range)
      this.$refs.startDate.picker.redraw()
    },
    getFilteredTechnicians() {
      this.techniciansList = this.userList
    },
    getFilteredPersonas() {
      this.personaList = this.personas?.data
    },
    searchTechnician(text) {
      Search.debouncedSearchByUri(text, 'user/searchTechnician', this.$store)
    },
    searchPersona(text) {
      Search.debouncedSearchByUri(text, 'user/searchPersona', this.$store)
    },
    clearTechnicianFilter() {
      this.clearFilter('technician')
      this.setUserList([])
    },
    clearPersonaFilter() {
      this.clearFilter('personas')
      this.setPersonaList([])
    },
  },

  watch: {
    userList: 'getFilteredTechnicians',
    personaList: 'getFilteredPersonas'
  }
}
</script>

<style scoped>
.box {
  z-index: 5;
}
</style>
