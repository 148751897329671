<template>
<page v-bind="{ loading }">
  <div class="box">
    <h3 class="title is-5">Multi-factor Authentication</h3>

    <div v-if="!mfaIsActive">
      <p>Your account does not currently use multi-factor authentication. Click the button below to improve the security of your account.</p>
      <action-button @click="openModal">Activate 2 Factor Authentication</action-button>
    </div>
    <div v-else>
      <p>Multi-factor Authentication is enabled.</p>
      <action-button @click="disableTwoFa">Disable 2 Factor Authentication</action-button> 
    </div>
  </div>
</page>    
</template>
<script>
import { mapGetters } from 'vuex'
import { twoFactorAuthentication } from '@/modals'
import backend from '@/api/auth'

export default {
  
  data: () => ({
    loading: false
  }),

  methods: {
    async openModal() {
      if(await twoFactorAuthentication()) {
        this.$store.commit('auth/twoFaActivated')
      }
    },

    async disableTwoFa() {
      if(await this.$confirmPassword()) {
        backend.disabled2Fa(() => {
          this.$store.commit('auth/twoFaDisabled')
          this.$toast.success('Two Factor Authentication Disabled')
        }, error => {
          this.$toast.error('Whoops something went wrong')
        })
      }
    }
  },

  computed: {
    ...mapGetters('auth', [
      'mfaIsActive'
    ])
  }

}
</script>