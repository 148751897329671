<template>
  <section class="grid gap-1">
    <!-- Header -->
    <header class="is-flex align-end justify-between sticks-to-navbar">
      <div>
        <h3 class="is-size-4 has-text-primary">{{ Naming.User }} Permissions</h3>
        <p>Manage the {{ Naming.Users.toLowerCase() }} permissions within the system.</p>
      </div>
      <!-- <div class="flex-grow plr-2"></div> -->
      <div class="buttons">
        <action-button 
          :working="downloading" 
          @click="downloadUserPermissionsReport"
          class="is-rounded is-primary">
          Download Report
        </action-button>
        <action-button
          :disabled="$root.hasAbility('manage-permissions', 'App\\User') === false || isPrimaryUser"
          @click="clearPermissions"
          class="is-rounded is-danger is-inverted">
          Deselect All
        </action-button>
        <action-button
          :disabled="$root.hasAbility('manage-permissions', 'App\\User') === false || isPrimaryUser"
          @click="selectAll"
          class="is-rounded is-success is-inverted">
          Select All
        </action-button>
      </div>
    </header>

    <!-- Permissions List -->
    <div class="columns is-multiline">
      <div class="column is-6" v-for="(abilities, index) in abilityList" :key="index">
        <article class="box p-1">
          <div>
            <h4 class="is-size-4">{{ cleanName(index) }}</h4>
            <a @click="toggleAbility(ability)" v-for="ability in abilities" :key="ability.id" class="is-flex align-center selectable-item"
              :class="{
                'is-selected': hasAbility(ability) || isPrimaryUser
              }">
              <icon
                class="mr-1"
                icon="check-circle"/>
              <div class="is-flex flex-grow align-center">
                <span class="has-text-weight-medium" :class="{
                    'has-text-grey': hasAbility(ability) === false || isPrimaryUser
                  }">
                  {{ Convert(ability.title) }}
                </span>
              </div>
            </a>
          </div>
          <!-- <loader v-bind="{ loading }">
            <no-data
              title="No Permissions"
              description="There are no permissions available on your account."/>
          </loader> -->
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'

import findIndex from 'lodash/findIndex'
import groupBy from 'lodash/groupBy'
import { common as backend } from '@/api'
import download from 'downloadjs'

export default {

  data: () => ({
    loading: true,
    downloading: false,
    filtering: false,
    syncing: false,
    filters: {
      search: ''
    }
  }),

  created() {
    this.$store.dispatch('ability/loadAbilities');
  },

  async beforeMount() {
    this.loading = false
  },

  methods: {
    clearPermissions() {
      this.$store.commit('user/setAbilities', [])
      this.$store.dispatch('ability/removeAllAbilities', this.user.id)
    },
    selectAll() {
      this.$store.commit('user/setAbilities', this.abilities)
      this.$store.dispatch('ability/allowAllAbilities', this.user.id)
    },
    hasAbility(ability) {
      return this.user.abilities.filter(abl => abl.id === ability.id).length > 0
    },
    allowAbility(ability) {
      this.$store.commit('user/allowAbility', ability)
      this.$store.dispatch('ability/allowAbility', {
        user_id: this.user.id,
        ability_id: ability.id,
        ability_name: ability.name,
      })
    },
    removeAbility(ability) {
      this.$store.commit('user/removeAbility', ability)
      this.$store.dispatch('ability/removeAbility', {
        user_id: this.user.id,
        ability_id: ability.id,
        ability_name: ability.name,
      })
    },
    toggleAbility(ability) {
      if(this.isPrimaryUser) return
      if(this.hasAbility(ability)) {
        this.removeAbility(ability)
      } else {
        this.allowAbility(ability)
      }
    },
    cleanName(ability) {
      return this.Convert(
          ability
              .replace('Billow\\Models\\', '')
              .replace('App\\Models\\', '')
              .replace('App\\', '')
              .replace(/([A-Z])/g, ' $1')
              .trim()
      );
    },
    downloadUserPermissionsReport(){
    this.downloading = true
      backend.downloadFile(('/api/user/' + this.user.id +'/download-permissions'), ({ data }) => {
        this.downloading = false
        download(
          data,
          `${this.Convert('user').toLowerCase()}-permissions.xlsx`,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
      }, error => {
        this.$whoops()
        this.downloading = false
      })
    }
  },

  computed: {
    ...mapState('site', [
      'clientGroupedSiteList'
    ]),
    ...mapGetters('user', [
      'user'
    ]),
    ...mapGetters('ability', [
      'abilities'
    ]),
    ...mapGetters('company', [
      'company'
    ]),
    isPrimaryUser(user) {
      return this.user.id === this.company.primary_user_id
    },
    abilityList() {

      return groupBy(this.abilities, 'entity_type')
    }
  },

}
</script>
