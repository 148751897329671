<template>
  <div class="sub-asset-item is-flex align-center justify-between" :class="{'is-staged' : isStaged}">
    <div class="is-flex align-center justify-between">
      <icon icon="building" class="is-size-6 grab-cursor"/>
      <div class="is-flex align-center">
        <p class="has-tooltip sub-asset-item-text"
           :class="{'assigned' : !isAvailableItems}"
           :data-tooltip="`${Naming.Site}: ${site.name}`">
          {{ site.name }}
        </p>
        <p v-if="!isAvailableItems"
           class="has-tooltip sub-asset-item-text assigned is-capitalized"
           :data-tooltip="`${Naming.Client}: ${site.client}`">
          <icon class="is-inline is-size-7" icon="address-book"/>
          {{ site.client }}
          <span v-if="isStaged === true" class="new-tag"
          >Not Saved</span>
        </p>
        <p v-if="isAvailableItems"
           class="ml-1 has-tooltip sub-asset-item-text"
           :data-tooltip="`${Naming.Site} Creation Date`">
          <icon class=" is-inline is-size-7" icon="calendar"/>
          {{ site.created_at | date('Y-MM-DD') }}
        </p>
        <p v-if="isAvailableItems"
           class="ml-1 has-tooltip sub-asset-item-text is-capitalized"
           :data-tooltip="`${Naming.Client}`">
          <icon class=" is-inline is-size-7" icon="address-book"/>
          {{ site.client ?? site.company }}
        </p>
      </div>
    </div>
    <icon @click="removeFromSelected" v-if="canBeRemoved" class="is-size-5 pl-1 pr-1 hover-highlight-danger"
          icon="times-circle"/>
    <span v-if="!canBeRemoved" class="new-tag"
  >Has Open Jobs</span>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'assigned-site-item',

  props: {
    site: {
      type: Object,
    },
    isAvailableItems: {
      type: Boolean,
      default: false
    },
    staged: {
      type: Array
    }
  },

  methods: {
    pushToSelected() {
      this.$emit('pushToSelected', this.site)
    },
    removeFromSelected() {
      this.$emit('removeFromSelected', this.site)
    },
  },

  computed: {
    ...mapGetters("userPersonas", ["persona"]),
    isStaged(){
      return this.staged?.includes(this.site)
    },
    canBeRemoved() {
      let site = this.persona?.sites?.find(assignedSite => assignedSite.site_id === this.site.id)
      if(site) {
        return site.open_job_count === 0
      }

      return true
    },
  }
}
</script>