<template>
  <page icon="users" :title="`New ${Naming.User} Persona`" v-bind="{ loading }" :loadingText="loadingText">
    <template #tools>
      <router-link exact class="button is-rounded is-primary" :to="{ name: 'user-persona-index' }">
        <icon icon="users"/>
        <span>{{ Naming.User }} Personas</span>
      </router-link>
      <router-link exact class="button is-rounded is-primary ml-1" :to="{ name: 'user-persona-transactions' }">
        <icon icon="receipt"/>
        <span>{{ Naming.User }} Persona Transaction</span>
      </router-link>
    </template>
    <form class="box">
      <div class="columns is-multiline">
        <div class="column mr-4">
          <text-input
              placeholder="ex: Region Group, Collective Area, etc…"
              required
              :value="persona.name"
              @input="name">
            Name
          </text-input>
        </div>
        <div class="columns mr-4 mt-2">
          <submit-button :working="loading" @submit="store" class="is-success is-medium" :disabled="!canSave"
                         left-icon="check">Create
            {{ Naming.User }} Persona
          </submit-button>
        </div>
      </div>
      <br/>
      <div class="level mb-1">
        <div class="level-left">
          <h3 class="is-size-5 level-item">Assign {{ Naming.Sites }} to {{ Naming.User }} Persona</h3>
        </div>
      </div>
      <div class="box">
        <div class="grid has-5-columns gap-1">
          <!--ASSIGNED SITES-->
          <div class="is-2-columns">
            <div class="tab">Assigned To {{ Naming.User }} Persona</div>
            <draggable :list="selectedSites" group="subAssetSelector"
                       class="sub-asset-draggable has-background-white-bis is-fullheight"
                       v-if="selectedSites.length < maximumSitesToDisplay"
            >
              <div v-if="hasSelectedSites" v-for="(site, index) in selectedSites" :key="site.id">
                <site-item :available-items="false" :index="index"
                           :site="site" :staged="stagedSites"
                           @removeFromSelected="removeSiteFromSelectedSites"/>
              </div>
              <div v-if="!hasSelectedSites" class="has-text-grey p-1">No {{ Naming.Sites }} yet. Select or drag from
                available {{ Naming.Sites }}.
              </div>
            </draggable>
            <draggable :list="selectedSites" group="subAssetSelector"
                       class="sub-asset-draggable has-background-white-bis is-fullheight"
                       v-else
            >
              <div class="grid has-1-columns gap-1 p-1 has-text-centered">
                Showing first {{ this.maximumSitesToDisplay }} of {{ this.selectedSites.length}} selected {{ Naming.Sites}}
              </div>
              <div v-if="hasSelectedSites" v-for="(site, index) in abbreviatedSelectedSites" :key="site.id">
                <site-item :available-items="false" :index="index"
                           :site="site" :staged="stagedSites"
                           @removeFromSelected="removeSiteFromSelectedSites"/>
              </div>
              <div v-if="!hasSelectedSites" class="has-text-grey p-1">No {{ Naming.Sites }} yet. Select or drag from
                available {{ Naming.Sites }}.
              </div>
            </draggable>
          </div>

          <!--AVAILABLE SITES-->
          <div class="is-3-columns">
            <div class="tab available is-flex justify-between">
              <div class="">Available {{ Naming.Sites }}
              </div>
              <div>
                Showing {{ this.availableSites?.data?.length }} of {{ calculatedTotalSites }}
              </div>
            </div>

            <div>
              <div class="search-box grid has-4-columns gap-1">
                <div class="is-flex is-3-columns">
                  <input
                      type="text"
                      v-model="search"
                      class="input"
                      placeholder="Search by name"
                  />
                  <div class="is-flex">
                    <action-button
                        v-if="search"
                        @click="clearSearch()"
                        class="is-info-lighter">
                      <span>Clear</span>
                      <icon icon="times"/>
                    </action-button>
                  </div>
                  <div class="is-flex">
                    <action-button @click="searchAvailableSites()" class="ml-1 is-info-lighter">Search</action-button>
                  </div>
                </div>
                <action-button left-icon="plus-circle" class="is-pulled-right" @click="addAllSites()" :working="addingAllSites">Add All {{ Naming.Sites }} to this Persona</action-button>
              </div>

              <div class="search-box grid has-4-columns gap-1">
                <div class="is-flex is-3-columns">
                  <checkbox-input :value="searchClientsOnly" inner-label="Search Clients Only" @input="toggle('searchClientsOnly')"/>
                  <checkbox-input v-if="searchClientsOnly" :value="returnAllSitesForClient" inner-label="Return All Sites for Client" @input="toggle('returnAllSitesForClient')"/>
                </div>
                <div>&nbsp;</div>
              </div>
              <div class="search-box grid has-3-columns gap-1">
                <div class="is-flex">
                  <action-button left-icon="plus" @click="selectAll()" class="is-info-lighter">Select All</action-button>
                  <action-button left-icon="sort" @click="sortSites('name')">Site Name</action-button>
                  <action-button left-icon="sort" @click="sortSites('client')">Client Name</action-button>
                </div>
              </div>

              <draggable :list="this.availableSites?.data" group="subAssetSelector" class="sub-asset-draggable">
                <div v-for="(availableSite, index) in this.availableSites.data" :key="availableSite.id">
                  <site-item v-if="!loadingMoreSites" :is-available-items="true"
                             :index="index" class="available-item"
                             :site="availableSite" @pushToSelected="pushSiteToSelected"/>
                </div>
                <loader v-if="loadingMoreSites" v-bind="{ loadingMoreSites }"
                        :text="`Loading More ${Naming.Sites}`"></loader>
                <div v-if="moreSitesAvailable">
                  <div
                      class="persona-load-more-box has-text-grey is-flex-column align-items-center justify-between"
                      @click="loadMoreSites()"
                  >
                    <div class="has-text-grey has-text-centered p-05">
                      Showing {{ this.availableSites?.data?.length }} of {{ calculatedTotalSites }}
                    </div>
                    <icon class="is-size-5" icon="plus-circle"></icon>
                  </div>
                </div>
                <!--placeholder to give height when no subasset items-->
                <div class="p-2">&nbsp</div>
              </draggable>
              <div v-if="!this.availableSites?.data">
                <div class="has-text-grey p-05">No additional available {{ Naming.Sites.toLowerCase() }}.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br/>
    </form>

  </page>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import ChangeZone from "@/views/asset/manager/partials/ChangeZone.vue";
import SiteItem from "@/views/user/personas/partials/SiteItem.vue";
import {common as backend} from "@/api";

export default {
  components: {SiteItem, ChangeZone},

  data: () => ({
    maximumSitesToDisplay: 100,
    loading: false,
    search: '',
    loadingSites: false,
    loadingMoreSites: false,
    sortDescending: {
      name: false,
      created_at: false,
    },
    searchClientsOnly: false,
    returnAllSitesForClient: false,
    addingAllSites: false,
    processingTextSiteCountLimit: 1000,
  }),

  created() {
    this.loadAvailableSites()
  },

  beforeDestroy() {
    this.clearUserPersona()
  },

  methods: {
    ...mapMutations('userPersonas', [
      'clearUserPersona',
      'name',
      'sites',
    ]),
    toggle(item) {
      if(item) {
        this[item] = !this[item]
      }
    },
    store() {
      this.loading = true
      let payload = {
        name: this.persona.name,
        sites: this.selectedSites.map((site) => {
          return {site_id: site.id}
        })
      }
      this.$store.dispatch('userPersonas/store', payload).then(userPersona => {
        this.$toast.success(this.Convert(this.$lang.userPersona.created))
        this.loading = false
        this.$router.back()
      }).catch(error => {
        this.loading = false
        if(error.response.status === 422) {
          this.$toast.warning("The Name already exists for this Persona.")
        }else {

          this.$whoops()
        }
      })
    },
    async searchAvailableSites() {
      this.loadingMoreSites = true
      this.availableSites.data = []
      backend.loadPathPost(
          this.availableSites.links.first,
          this.getPayload(),
          ({data}) => {
            this.availableSites.links.next = data.links.next
            this.availableSites.meta = data.meta
            data.data.forEach(site => this.availableSites.data.push(site))
          },
          error => {
          })
          .finally(() => {
            this.loadingMoreSites = false
          })
    },
    async loadAvailableSites() {
      this.loadingMoreSites = true
      const payload = this.getPayload()
      await this.callStoreAction('userPersonas/availableSites', payload).finally(() => this.loadingMoreSites = false)
    },
    async callStoreAction(action, payload) {
      await this.$store.dispatch(
          action,
          payload
      ).catch((error) => {
        console.log(error)
        this.$whoops()
      })
    },
    getPayload() {
      return {
        search: this.search,
        selected_site_ids: this.selectedSiteIds,
        search_clients_only: this.searchClientsOnly,
        return_all_sites_for_client: this.returnAllSitesForClient
      }
    },
    removeSiteFromSelectedSites(site) {
      this.$store.commit('userPersonas/removeSite', site)
      this.availableSites.data.push(site)
    },
    clearSearch() {
      this.search = ''
      this.searchClientsOnly = false
      this.returnAllSitesForClient = false
      this.loadAvailableSites()
    },
    selectAll() {
      this.availableSites.data.forEach(site => this.selectedSites.push(site))
      this.loadAvailableSites()
    },
    async addAllSites() {
      //  we need to mark the action as busy
      this.addingAllSites = true
      this.loadingSites = true
      this.loadingMoreSites = true

      await this.$store.dispatch('userPersonas/addAllSites').then(() => this.loadAvailableSites()).catch((error) => {
        console.log(error)
        this.$whoops()
      }).finally(() => {
        this.addingAllSites = false
        this.loadingSites = false
        this.loadingMoreSites = false
      })
    },
    sortSites(key) {
      let isDescending = this.sortDescending[key]
      this.availableSites.data = this.sortArray(this.availableSites?.data, key, isDescending)
      this.sortDescending[key] = !this.sortDescending[key]
    },
    sortArray(array, key, descending = false) {
      return array.sort((a,b) => {
        let valueA = a[key]
        let valueB = b[key]

        if(typeof valueA === 'string') {
          valueA = valueA?.toLowerCase()
          valueB = valueB?.toLowerCase()
        }

        return descending ? (valueB?.localeCompare(valueA)) : (valueA?.localeCompare(valueB))
      })
    },
    pushSiteToSelected(site) {
      let index = this.availableSites.data.indexOf(site)
      this.availableSites.data.splice(index, 1)
      this.selectedSites.push(site)
    },
    loadMoreSites() {

      this.loadingMoreSites = true
      let payload = this.getPayload()
      this.apiRequest(payload)
    },
    apiRequest(payload) {
      backend.loadPathPost(
          this.availableSites.links.next,
          payload,
          ({data}) => {
            this.availableSites.links.next = data.links.next
            this.availableSites.meta = data.meta
            data.data.forEach(site => this.availableSites.data.push(site))
          },
          error => {
          })
          .finally(() => {
            this.loadingMoreSites = false
          })
    },
  },

  computed: {
    ...mapGetters('userPersonas', [
      'persona',
      'selectedSites',
      'availableSites'
    ]),
    abbreviatedSelectedSites() {
      return this.selectedSites.slice(0, this.maximumSitesToDisplay)
    },
    hasSelectedSites() {
      return this.selectedSites.length > 0
    },
    stagedSites() {
      if (this.selectedSites && this.persona.sites) {
        return this.selectedSites.filter(site => !this.persona.sites.includes(site))
      }
    },
    selectedSiteIds() {
      if (this.selectedSites) {
        return this.selectedSites.map(site => site.id)
      }
      return []
    },
    calculatedTotalSites() {
      if (this.moreSitesAvailable) {
        const toBeLoaded = this.availableSites?.meta.total - this.availableSites?.meta.to
        return this.availableSites?.data?.length + toBeLoaded
      }
      return this.availableSites?.data?.length
    },
    moreSitesAvailable() {
      return this.availableSites?.links?.next?.length > 0
    },
    canSave() {
      return this.persona.name.length > 2 && this.selectedSites.length > 0
    },
    loadingText() {
      if(this.selectedSites.length > this.processingTextSiteCountLimit) {
        return `Processing ${this.selectedSites.length} ${ this.Naming.Sites }, this may take a while.`
      }

      return "Loading..."
    },
  }
}
</script>
