<template>
<base-modal :overlay-closes="false" title="Enable 2 Factor Authentication">
  <loader v-bind="{ loading }">
    <div class="columns">
      <div class="column has-text-centered">
        <p class="is-block">
          Scan the QR code shown below in your authenticator application. 
          We recommend Google Authenticator on Google Play or the App Store.
        </p>
        <div class="mt-1" v-html="svg"></div>
      </div>
      <div class="column">
        <p class="has-text-info">Recovery Codes</p>
        <p class="has-text-danger">
          Copy the Recovery Codes below to a safe location. These recovery codes will only be shown once. 
          If you are locked out of your account you will need these recovery codes.
        </p>
        <div class="mt-1">
          <p v-for="(code, index) in recovery_codes" :key="index">{{ code }}</p>
        </div>
      </div>
    </div>

    <form>
      <div class="columns">
        <div class="column">
          <text-input 
            :error="$root.errors.code"
            v-model="challenge.code"
            required
            classes="has-addons is-large"
            description="Enter the code provided by your two factor application">
            Code
            <template #right>
              <submit-button :working="working" @submit="validateCode" class="is-rounded is-large">Activate 2FA</submit-button>
            </template>
          </text-input>
        </div>
      </div>
    </form>
  </loader>
</base-modal>  
</template>
<script>
import backend from '@/api/auth'

export default {

  data: () => ({
    working: false,
    loading: true,
    svg: null,
    recovery_codes: [],
    challenge: {
      code: ''
    }
  }),
  
  created() {
    Promise.all([
      this.$store.dispatch('auth/startTwoFa')
    ]).finally(() => {
      backend.load2FaQrCode(({data}) => {
        this.svg = data.svg
        this.recovery_codes = data.recovery_codes
        this.loading = false
      }, error => {
        this.loading = false
      })
    })
  },

  methods: {
    validateCode() {
      this.$store.dispatch('auth/finalise2FaSetup', this.challenge).then(() => {
        this.$close(true)
        this.$toast.success('Two Factor Authentication Successfully Setup.')
      }).catch(error => {
        console.log(error)
      })
    }
  }

}
</script>