<template>
  <base-modal :title="`Download ${Naming.Job} Report for ${this.user.name}`">
    <div class="grid gap-1">
      <date-picker
        :disabled="working"
        class="is-marginless"
        placeholder="Select dates"
        :description="`Only include ${Naming.Jobs.toLowerCase} where the start date falls between the dates below:`"
        mode="range"
        left-icon="calendar"
        v-model="range">
        Date Range
      </date-picker>

      <data-selector
        :disabled="working"
        class="is-marginless"
        :items="jobStatuses"
        prompt-label="Select a status"
        :description="`Optionally filter by ${Naming.Jobs.toLowerCase()} with a current status of:`"
        v-model="status">
        Filter by Status (optional)
      </data-selector>
    </div>

    <template #footer>
      <action-button @click="downloadReport" class="is-primary" v-bind="{ working }">Download Report</action-button>
    </template>
  </base-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { user as backend } from '@/api'
import download from 'downloadjs'

export default {

  data: () => ({
    working: false,
    range: '',
    status: ''
  }),

  created() {
    this.range = moment().startOf('month').format('Y-MM-DD') + ' to ' + moment().format('Y-MM-DD')
  },

  computed: {
    ...mapGetters('list', ['jobStatuses']),
    ...mapGetters('user', ['user']),
  },

  methods: {
    downloadReport() {
      this.$toast.info('Downloading report…')
      this.working = true
      backend.downloadJobHistoryByDateRange({
        userId: this.user.id,
        date_range: this.range,
        status: this.status
      }, ({ data }) => {
        this.$toast.info('Download complete - please choose a location to save the PDF.')
        download(data, `${this.Convert('Job')} History for ${this.user.full_name} (${this.range}).pdf`, 'application/pdf')
        this.$close(true)
      }, error => {
        this.$whoops()
        this.working = false
      })
    },
  }

}
</script>
